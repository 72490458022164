import React from 'react'
import { useContext, useEffect, useRef } from 'react'
import eventContext from '../../../contexts/EventContext'
import ReadMore from '../../../components/ReadMoreText'
import useOnScreen from '../../../components/useOnScreen'


const EventAboutMinimalistic = ({ setCurrSection }) => {

    const ref = useRef(null)

    const { eventData } = useContext(eventContext)

    return (
        <>
            <section id='about' className='mt-4 mt-sm-4' ref={ref}>
                <div className='py-2'>
                    <div>
                        <h2 className='mb-2 ' style={{ fontSize: '20px', color: '#000' }}>ABOUT EVENT</h2>
                    </div>

                    <p className='about-text py-1 mt-1' style={{ color: '#141B25' }}>
                        {eventData.description && <div>
                            <p
                                dangerouslySetInnerHTML={{
                                    __html: `${eventData.description?.replace(/(?:\r\n|\r|\n)/g, "") || ""}`
                                }}
                                onClick={(e) => e.stopPropagation()}
                                styles={{
                                    fontFamily: "Manrope",
                                    margin: "0px",
                                    fontSize: "16px",
                                    lineHeight: "22px",
                                    color: '#141B25'
                                }}
                            />
                        </div>}
                    </p>
                </div>
            </section>
        </>
    )
}

export default EventAboutMinimalistic